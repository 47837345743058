import { RightOrLeftLabelType } from '../../types';
import styles from './styles.module.scss';

import clsx from 'clsx';

export const RightOrLeftLabel: React.FC<RightOrLeftLabelType> = (props) => {
  return (
    <label
      className={clsx(styles.base, styles[props.position])}
      htmlFor={props.for}
      style={{ width: props.width }}
    >
      {props.position === 'left' ? (
        <>
          {LabelText(props)}
          {props.children}
        </>
      ) : (
        <>
          {props.children}
          {LabelText(props)}
        </>
      )}
    </label>
  );
};

const LabelText = (props: RightOrLeftLabelType) => (
  <span
    className={clsx(
      styles.text,
      styles[props.typography.color ?? 'gray100'],
      styles[props.typography.lineHeight ?? 'tight'],
    )}
    style={{
      marginRight: props.position === 'left' ? props.distance : undefined,
      marginLeft: props.position === 'right' ? props.distance : undefined,
      fontSize: props.typography.fontSize,
      fontWeight: props.typography.fontWeight ?? 'bold',
    }}
  >
    {props.text}
  </span>
);
