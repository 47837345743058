import { TextButton } from '@/src/components/foundations/Buttons/TextButton';
import { Tooltip } from '@/src/components/foundations/DataDisplays/Tooltip';
import { Help } from '@/src/components/foundations/DesignToken/Icons';

import { TopLabelType } from '../../types';
import styles from './styles.module.scss';

import clsx from 'clsx';

export const TopLabel: React.FC<TopLabelType> = (props) => {
  return (
    <label
      className={clsx(styles.base, styles[props.position])}
      htmlFor={props.for}
      style={{ width: props.width }}
    >
      {LabelText(props)}
      <div className={styles.children}>{props.children}</div>
      <div
        className={styles.action}
        style={{
          marginBottom: props.position === 'top' ? props.distance : void 0,
        }}
      >
        {/*
          表示上はラベルテキスト > (アクションボタン) > children のように表示順にマークアップするのが自然だが、
          アクションボタンが暗黙的にlabelに関連付けられてしまうため、アクションボタンとchildrenを逆順にし、表示順はスタイルで調整している
        */}
        {props.actionButton && (
          <TextButton
            type="button"
            variant="normal"
            onClick={props.actionButton.onClick}
            disabled={props.actionButton.disabled}
          >
            {props.actionButton.text}
          </TextButton>
        )}
      </div>
    </label>
  );
};

const LabelText = (props: TopLabelType) => (
  <span
    className={clsx(
      styles.text,
      styles[props.typography.color ?? 'gray100'],
      styles[props.typography.lineHeight ?? 'tight'],
    )}
    style={{
      marginBottom: props.distance,
      fontSize: props.typography.fontSize,
      fontWeight: props.typography.fontWeight ?? 'bold',
    }}
  >
    {props.text}
    {props.required && <span className={styles.required}>※必須</span>}
    {props.tooltip?.children && (
      <span className={styles.helptext}>
        <Tooltip
          direction={props.tooltip.direction ?? 'top'}
          size={props.tooltip.size ?? 'medium'}
          trigger={props.tooltip.trigger ?? <Help color="primary" size={18} />}
        >
          {props.tooltip.children}
        </Tooltip>
      </span>
    )}
  </span>
);
